import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import petsALife from '../assets/petalife_logo.png';

// Create a navigation bar
const Bar = ({ isMobile, toggleLanguage, language }) => {

    // Manage the opening and closing status of the drawer menu
    const [open, setOpen] = useState(false);

    // Control navigation jump
    const navigate = useNavigate();

    // Toggle the opening and closing status of the drawer menu
    const toggleDrawer = (value) => () => {
        setOpen(value);
    };

    // Handle navigation to jump to the specified page and close the drawer menu
    const handleNavigation = (path) => {
        navigate(path);
        setOpen(false);
    }

    return (
        //Account authenticator
        <Authenticator socialProviders={['google']}>
            {({ signOut }) => (
                < AppBar position="static" style={{ background: '#FAE5D3' }}>

                    {/*Top navigation bar*/}
                    <Toolbar>

                        {/*Menu button, which will open the drawer menu when clicked*/}
                        <IconButton
                            size="large"
                            edge="start"
                            color="black"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>

                        {/*Title, centered*/}
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        >
                        </Typography>
                        <div className="center">
                            <Typography
                                variant="h4"
                                component="div"
                                sx={{ flexGrow: 1 }}
                                color="common.black"
                            >
                                <img src={petsALife} width={isMobile ? 150 : 250} />
                            </Typography>
                        </div>
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        >
                        </Typography>

                        {/*Button, switch between Chinese and English languages*/}
                        <Box mr={2}>
                            <Button
                                color="inherit"
                                onClick={toggleLanguage}
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FAE5D3',
                                    color: 'black', // or any other color that suits your design
                                    '&:hover': {
                                        backgroundColor: '#f9d9b8' // slightly darker for hover, adjust as needed
                                    }
                                }}
                            >
                                {language === 'zh' ? 'Switch to English' : '切換至中文'}
                            </Button>
                        </Box>

                        {/*Button, sign out*/}
                        <Button
                            color="inherit"
                            onClick={signOut}
                            variant="contained"
                            sx={{
                                backgroundColor: '#FAE5D3',
                                color: 'black', // or any other color that suits your design
                                '&:hover': {
                                    backgroundColor: '#f9d9b8' // slightly darker for hover, adjust as needed
                                }
                            }}
                        >
                            {language === 'zh' ? '登出' : 'Sign Out'}
                        </Button>
                    </Toolbar>

                    {/*Left drawer menu*/}
                    <Drawer
                        open={open}
                        onClose={toggleDrawer(false)}
                        PaperProps={{
                            sx: {
                                background: '#FAE5D3',
                                width: isMobile ? '50vw' : 250,
                            },
                        }}
                    >
                        <Box
                            sx={{ width: isMobile ? 150 : 250 }}
                            role="presentation"
                            onClick={() => toggleDrawer(false)}
                            style={{ background: '#FAE5D3' }}
                        >

                            {/*Close button*/}
                            <IconButton
                                style={{ marginLeft: isMobile ? '90%' : '80%' }}
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 5 }}
                                onClick={() => toggleDrawer(false)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <br />

                            {/*Button，navigate to Home*/}
                            <Link to="/">
                                <button
                                    className="chatbox-button"
                                    style={{ background: '#FAE5D3' }}
                                    onClick={() => handleNavigation('/')}
                                >
                                    {language === 'zh' ? '主頁' : 'Home'}
                                </button>
                            </Link>
                            <br />

                            {/*Button，navigate to About Us*/}
                            <Link to="/about">
                                <button
                                    className="chatbox-button"
                                    style={{ background: '#FAE5D3' }}
                                    onClick={() => handleNavigation('/about')}
                                >
                                    {language === 'zh' ? '關於我們' : 'About Us'}
                                </button>
                            </Link>
                            <br />

                            {/*Button，navigate to AI Detection System*/}
                            <Link to="/aichatbox">
                                <button
                                    className="chatbox-button"
                                    style={{ background: '#FAE5D3' }}
                                    onClick={() => handleNavigation('/aichatbox')}
                                >
                                    {language === 'zh' ? 'AI監察系統' : 'AI Detection System'}
                                </button>
                            </Link>
                            <br />

                            {/*Button，navigate to Contact Us*/}
                            <Link to="/contact">
                                <button
                                    className="chatbox-button"
                                    style={{ background: '#FAE5D3' }}
                                    onClick={() => handleNavigation('/contact')}
                                >
                                    {language === 'zh' ? '聯絡我們' : 'Contact Us'}
                                </button>
                            </Link>
                            <br></br>
                        </Box>
                    </Drawer>
                </AppBar>
            )}
        </Authenticator>
    );
};

export default Bar;