import React from 'react';

import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@material-ui/core/TextField';
import petsImage from '../assets/1.jpeg'; 

function ContactUs({ toggleLanguage, language }) {

    // Detect whether the current device is a mobile device
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box sx={{ flexGrow: 1 }}>

            {/*Table, centered*/}
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align="center">
                <br></br>
                <img src={petsImage} alt="Happy Pets" className="landing-image" width={isMobile ? 300 : 500}/>
                <br></br>
                <TextField id="outlined-basic" label="Last name" variant="outlined" style = {{width: 300}}/>
                <TextField id="outlined-basic" label="First name" variant="outlined" style = {{width: 300}}/>
                <br></br>
                <TextField id="outlined-basic" label="Email" variant="outlined" style = {{width: isMobile ? 300:600}}/>
                <br></br>
                <TextField id="outlined-basic" label="Phone" variant="outlined" style = {{width: isMobile ? 300:600}}/>
                <br></br>
                <TextField id="outlined-basic" label="Type your message here" variant="outlined" inputProps={{ style: { height: '70px' } }} style = {{width: isMobile ? 300:600}} />
                <br></br>        
            </Typography>
        </Box>
    );
}

export default ContactUs;
