import './AIChatbox.css';

import React, { useState, useEffect, useRef } from 'react';
import { MuiFileInput } from 'mui-file-input';
import Loading from './Loading';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';

import { fetchUserAttributes } from '@aws-amplify/auth';

function AIChatbox({ toggleLanguage, language }) {

    // State to store the data
    const [data, setData] = useState(null);
    // State to manage loading state
    const [isLoading, setIsLoading] = useState(true);
    // State to manage errors
    const [error, setError] = useState(null);

    // Detect whether the current device is a mobile device
    const isMobile = useMediaQuery('(max-width:600px)');

    // Get the user's attributes
    const [userAttributes, setUserAttributes] = useState(null);

    // Welcome Message
    const welcomeMessage = (language) => {
        if (language === 'zh') {
            return (
                <>
                    你好，我是由Petalife團隊研發的人工智慧獸醫助理。
                    <br />
                    我的工作是協助寵物預先診斷，以便及早發現和治療。
                    <br />
                    你可上傳寵物糞便的照片以檢查其健康狀況或；
                    <br />
                    問我任何寵物健康相關的問題，例如：「我應該給我的寵物吃什麼令他的腸道更健康？」以獲取即時的建議。
                </>
            );
        } else {
            return (
                <>
                    Hello, I am an AI veterinary assistant developed by the Petalife team.
                    <br />
                    My job is to assist in the early diagnosis of pets, so that problems can be detected and treated early.
                    <br />
                    You can upload a photo of your pet's stool to check its health status, or;
                    <br />
                    Ask me any pet health - related questions, such as "What should I feed my pet to make their digestive system healthier?" to get immediate advice.
                </>
            );
        }
    };

    // Add welcome message when the component is first rendered
    const didAddWelcomeMessage = useRef(false);
    // Add welcome message when the language is switched
    //const [prevLanguage, setPrevLanguage] = useState('zh');

    // Execute when the component is first rendered
    useEffect(() => {
        // Asynchronously fetch data from an API
        const fetchData = async () => {
            try {
                const response = await fetch('https://4d2kgb9xrh.execute-api.us-east-2.amazonaws.com/UAT', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: '',
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setData(data);

                // // Add welcome message when the component is first rendered(Don't use now)
                // if (!didAddWelcomeMessage.current) {
                //     addMessage('bot', welcomeMessage(language));
                //     didAddWelcomeMessage.current = true;
                // }
                // Add welcome message when the language is switched
                //if (language !== prevLanguage) {
                //    addMessage('bot', welcomeMessage(language));
                //    setPrevLanguage(language);
                //}

                // Get the user's attributes
                const userAttributes = await fetchUserAttributes();
                setUserAttributes(userAttributes);

            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    // State to store the message during the chat
    const [messages, setMessages] = useState([]);
    // State to store the user input in the chatbox
    const [userInput, setUserInput] = useState('');
    // State to store the file the user uploaded
    const [selectedFile, setSelectedFile] = useState(null);
    // State to indicate whether the chatbot is typing a message
    const [botTyping, setBotTyping] = useState(false);

    // Logic for processing messages and/or images sent by users
    const handleSend = async () => {

        // setBotTyping(true)
        const thread_id = JSON.parse(data.body)
        // console.log(thread_id)

        // If there is neither text case nor image case, return directly
        if (!userInput.trim() && !selectedFile) return;

        // If only text case
        else if (userInput.trim() && !selectedFile) {

            // Indicate the chatbot is typing a message
            setBotTyping(true)

            const messagePayload = {
                thread_id: thread_id,

                // Set the language of the chatbot answers according to the application language
                user_text_input: language === 'zh' ? userInput.trim() + '(用中文答)' : userInput.trim() + '(Answer in English)',
                user_name:userName,
                user_id:userAttributes.sub,
                user_email:userAttributes.email,
            };

            // Add user message to the state of messages
            addMessage('user', userInput, '');

            // Clear the state of userInput and selectedFile
            setUserInput('');
            setSelectedFile(null);

            // Send user message to the backend
            await openaiAddMessageAPI(messagePayload)

            // Get chatbot response message
            const runThreadLoad = {
                thread_id: thread_id,
            };
            const botResponse = await openaiRunThreadAPI(runThreadLoad)

            // Iterate through chatbot responses and add them to the state of messages
            for (let i = botResponse.length; i > 0; i--) {
                const formattedText = botResponse[i - 1].replace(/\n/g, '<br>');
                const botMessage = <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
                addMessage('bot', botMessage); // Display bot response
            }

            // Indicate the chatbot finishes typing a message
            setBotTyping(false)
        }

        //If text and image case
        else {

            // Indicate the chatbot is typing a message
            setBotTyping(true)

            // Convert the selected file into a base64 string
            let base64Image = '';
            if (selectedFile) {
                base64Image = await convertToBase64(selectedFile);
            }

            // Add user message and image to the state of messages
            const messagePayload = {
                // thread_id: thread_id,
                // user_text_input: userInput.trim(),
                base64_string: base64Image,
                language: language,
                user_name:userName,
                user_id:userAttributes.sub,
                user_email:userAttributes.email,
            };
            addMessage('user', userInput, base64Image); // Display user message and/or image

            // Clear the state of userInput and selectedFile
            setUserInput('');
            setSelectedFile(null); // Reset the file input

            // Send user message and image to the backend
            const botResponse = await openaiAddMessageWithFileAPI(messagePayload)

            // Add chatbot response message to the state of messages
            const formattedText = botResponse.replace(/\n/g, '<br>');
            const botMessage = <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
            addMessage('bot', botMessage); // Display bot response

            // Indicate the chatbot finishes typing a message
            setBotTyping(false)

            // const runThreadLoad = {
            //   thread_id: thread_id,
            // };
            // const botResponse = await openaiRunThreadAPI(runThreadLoad)
            // for(let i = botResponse.length ;i>0;i--){
            //   addMessage('bot', botResponse[i-1]); // Display bot response
            // }
        }
    };

    // Add user message and/or image to the state of messages
    const addMessage = (sender, text, image = '') => {
        setMessages((prevMessages) => [...prevMessages, { sender, text, image }]);
    };

    // An unused example function: Send user message and/or image to the backend
    const sendMessageToBackend = async (messagePayload) => {
        // console.log("messagePayload.image:", messagePayload.image)
        // Placeholder: Replace 'https://your_api_gateway_endpoint' with your actual API Gateway endpoint
        const response = await fetch('https://your_api_gateway_endpoint', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.reply || 'Sorry, I could not process that.';
    };

    // Interact with OpenAI's API and obtain response
    const openaiAddMessageAPI = async (messagePayload) => {
        const response = await fetch('https://s8gv18zkt5.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload)
        });
        // console.log(messagePayload)
        //Return data
        const data = await response.json();
        return data || 'Sorry, I could not process that.';
    };

    const openaiAddMessageWithFileAPI = async (messagePayload) => {
        const response = await fetch('https://fcisclxd1l.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload)
        });

        // Extract msg_content from data and return it
        const data = await response.json();
        const dataJson = JSON.parse(data.body)
        const msgList = dataJson['msg_content']
        // console.log("dataaaaaaaaaa:", msgList)
        return msgList || 'Sorry, I could not process that.';
    };

    const openaiRunThreadAPI = async (messagePayload) => {
        const response = await fetch('https://ecr6o1jfo5.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload),
        });

        // Extract msg_list from data and return it
        const data = await response.json();
        const dataJson = JSON.parse(data.body)
        const msgList = dataJson['msg_list']
        // console.log("dataaaa:", msgList)
        return msgList || 'Sorry, I could not process that.';
    };

    // Process the `change` event of the file input control
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Process receiving a new file object
    const handleChange = (newFile) => {
        setSelectedFile(newFile);
    }

    // Convert the selected file into a base64 string
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    // If isLoading, render Loading component
    if (isLoading) return <Loading />;

    // Get User Name
    let userName = userAttributes.sub;

    if (userAttributes.identities) { 
        const userAttributesIdentities = JSON.parse(userAttributes.identities);
        userName = `google_${userAttributesIdentities[0].userId}`;
    }

    return (
        <Box sx={{ flexGrow: 1 }}>

            {/*Print out the user's attributes*/}
            {/* <div>
                {userAttributes && (
                    <div>
                        <h2>Welcome</h2>
                        <p>User Name: {userName}</p>
                        <p>User ID: {userAttributes.sub}</p>
                        <p>Email: {userAttributes.email}</p>
                    </div>
                )}
            </div> */}

            <div className="App">
                <header className="App-header">

                    {/*Area for displaying chat messages, supporting scrolling*/ }
                    <div id="chatbox" style={{ textAlign: 'left', width: isMobile ? 'calc(100% - 56px)' : '1200px', height: isMobile ? '600px' : '650px', overflow: 'auto', backgroundColor: '#f5f5f5', padding: '10px', marginBottom: '10px' }}>


                    <div style={{ marginBottom: '10px', textAlign: 'left' }}>
                        <div style={{ display: 'inline-block', maxWidth: '700px', padding: '5px', backgroundColor: '#e9ecef', color: 'black', borderRadius: '10px' }}>
                            <Typography variant="body1" component="div" sx={{ flexGrow: 1 }} >
                                {welcomeMessage(language)}
                            </Typography>
                        </div>
                    </div>

                        {/*Iterate over the chat message array and render an area with the message content for each message*/}
                        {messages.map((msg, index) => (
                            <div key={index} style={{ marginBottom: '10px', textAlign: msg.sender === 'user' ? 'right' : 'left' }}>
                                <div style={{ display: 'inline-block', maxWidth: '700px', padding: '5px', backgroundColor: msg.sender === 'user' ? '#FAE5D3' : '#e9ecef', color: msg.sender === 'black' ? 'white' : 'black', borderRadius: '10px' }}>
                                    {/* <Typography variant="body2" gutterBottom> */}

                                    {/*Render message text*/ }
                                    <Typography variant="body1" component="div" sx={{ flexGrow: 1 }} >
                                        {msg.text}
                                    </Typography>

                                    {/* {msg.text} */}
                                    {/* </Typography> */}

                                    {/*If the message contains an image, render an img element to display the image*/ }
                                    {msg.image && <img src={msg.image} alt="Uploaded" style={{ display: 'block', maxWidth: '100px', maxHeight: '100px', marginTop: '10px' }} />}

                                    {msg.FileReader}
                                    {msg.addMessage}
                                </div>
                            </div>
                        ))}

                        {/*If botTyping, render a loading indicator that the chatbot is typing a message*/ }
                        {botTyping ? <CircularProgress /> : ''}
                    </div>

                    {/*Area containing a user input box and a send button*/ }
                    <Box sx={{ width: isMobile ? '100%' : 1500 }} role="presentation" >

                        {/*A text box for the user to input a chat message*/ }
                        <TextField id="outlined-basic" label="" value={userInput} onChange={(e) => setUserInput(e.target.value)} variant="outlined" style={{ width: isMobile ? 'calc(100% - 90px)' : 1150 }} />

                        {/*A button for sending the chat message*/ }
                        <IconButton
                            // size="large"
                            // edge="start"
                            // color="inherit"
                            // color="black"
                            aria-label="menu"
                            // sx={{ mr: 1 }}
                            onClick={handleSend}
                            sx={{ p: 2 }}
                        >
                            {/* <MenuIcon /> */}
                            <SendIcon />
                        </IconButton>
                    </Box>

                    {/* <textarea value={userInput} onChange={(e) => setUserInput(e.target.value)} style={{ width: '1100px', height: '70px', marginBottom: '5px' }} placeholder="Type your message here"></textarea> */}
                    {/* <input type="file" onChange={handleFileChange} style={{ marginBottom: '5px' }} /> */}

                    {/*An input box for selecting a file to upload*/}
                    <MuiFileInput style={{ width: isMobile ? '90%' : '1205px', height: '70px', marginBottom: '5px' }} size="small" placeholder="Insert a file" value={selectedFile} onChange={handleChange} />

                    {/*label for triggering file selection*/ }
                    <label htmlFor="file-input">
                        <input
                            id="file-input"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleChange}
                        />
                        {/* <IconButton
                                style={{ width: '100px', height: '70px', marginBottom: '5px' }}
                                size="small"
                                component="span"
                                value={userInput} 
                                onChange={(e) => setUserInput(e.target.value)}
                             >
                                <AttachFileIcon />
                            </IconButton> */}
                    </label>
                    {/* <button onClick={handleSend}>Send</button> */}
                </header>
            </div>
        </Box>
    );
}

export default AIChatbox;
