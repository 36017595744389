import './LandingPage.css';

import React from 'react';
import { Link } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import homepage_title from '../assets/homepage_title.png';
import homepage_button from '../assets/homepage_button.png';
import homepage_title_en from '../assets/homepage_title_en.jpg';
import homepage_button_en from '../assets/homepage_button_en.jpg';
import homepage_picture from '../assets/homepage_picture.png';

function LandingPage({ toggleLanguage, language }) {

    // Detect whether the current device is a mobile device
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className="container">
            <Box sx={{ flexGrow: 1 }}>

                {/*Centered*/}
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align="center">
                    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'column', alignItems: 'center', justifyContent: 'center' }}>

                        {/*Title, switchable between Chinese and English*/}
                        {language === 'zh' ? (
                            <img src={homepage_title} width={isMobile ? 200 : 600} style={{ float: 'left', marginRight: '2px' }} />
                        ) : (
                            <img src={homepage_title_en} width={isMobile ? 200 : 600} style={{ float: 'left', marginTop: '8px', marginRight: '2px' }} />
                        )}

                        {/*Button and picture styles vary based on device type*/}
                        {isMobile
                            ? <>
                                {/*Button, picture, switchable between Chinese and English*/}
                                {language === 'zh' ? (
                                    <div>
                                        <Link to="/aichatbox">
                                            <img src={homepage_button} width={400} />
                                        </Link>
                                        <img src={homepage_picture} width={300} />
                                    </div>
                                ) : (
                                <div>
                                    <Link to="/aichatbox">
                                        <img src={homepage_button_en} width={400} />
                                    </Link>
                                    <img src={homepage_picture} width={300} />
                                </div>
                                )}
                            </>
                            : 
                            <>
                                {language === 'zh' ? (
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <Link to="/aichatbox">
                                                <img src={homepage_button} width={isMobile ? 200 : 600} style={{ float: 'left', marginRight: '2px' }} />
                                            </Link>
                                        </div>
                                        <div>
                                            <img src={homepage_picture} width={isMobile ? 200 : 600} />
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <Link to="/aichatbox">
                                                <img src={homepage_button_en} width={isMobile ? 200 : 600} style={{ float: 'left', marginRight: '2px' }} />
                                            </Link>
                                        </div>
                                        <div>
                                            <img src={homepage_picture} width={isMobile ? 200 : 600} />
                                        </div>
                                    </div>
                                )} 
                            </>
                        }

                        {/*T&C, switchable between Chinese and English*/}
                        {language === 'zh' ? (
                            <div style={{ fontsize: '1px', transform: 'scale(0.7)', fontFamily: 'Arial, sans-serif', color: 'grey' }}>
                                <br /><br />
                                我們的人工智慧寵物AI健康監測器僅供參考。它不提供醫療建議、診斷或治療。
                                <br />
                                它不能取代合格的醫療保健專業人員（例如您寵物的獸醫）的建議。
                                <br />
                                如果使用者對寵物的特定醫療狀況有任何疑問，
                                <br />
                                應聯繫合格的醫療保健專業人員，並在緊急情況下立即尋求他們的協助。
                            </div>
                        ) : (
                            <div style={{ fontsize: '1px', transform: 'scale(0.7)', fontFamily: 'Arial, sans-serif', color: 'grey' }}>
                                <br /><br />
                                Our AI pet health monitoring system is for reference only. It does not provide medical advice, diagnosis, or treatment.
                                <br />
                                It cannot replace the advice of qualified healthcare professionals (such as your pet's veterinarian).
                                <br />
                                If users have any questions about their pet's specific medical condition,
                                <br />
                                they should contact qualified healthcare professionals and seek their assistance immediately in emergency situations.
                            </div>
                        )}

                    </div>
                </Typography>
            </Box>
        </div>
    );
}

export default LandingPage;
