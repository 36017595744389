import React from 'react';

import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import aboutus_pic from '../assets/aboutus_pic.png';

function AboutUs({ toggleLanguage, language }) {

    // Detect whether the current device is a mobile device
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className="container">
            <Box sx={{ flexGrow: 1 }}>

                <br />
                {/*Image, centered*/}
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} align="center">
                    <img src={aboutus_pic} width={isMobile ? 300 : 600} />
                </Typography>

                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>

                    {/*Introductory text, switchable between Chinese and English*/}
                    {language === 'zh' ? (
                        <div>
                            <br />
                            創辦人 Eugene 和 Oscar 是一對姐弟，同時也是寵物愛好者。他們在童年時期，與一隻可愛的小狗Oreo度過了許多美好時光。然而，一個意外的健康問題卻在Oreo身上發生，由於未能及時得到適當的醫療治療，Oreo突然離世。這個不幸的事件深深觸動了Eugene和Oscar的心靈，讓他們充滿了內疚和遺憾。
                            <br /><br />
                            Eugene和Oscar深知，他們並不是唯一有著這樣痛苦經歷的寵物主人。他們認識到寵物的健康問題需要更好的監控和預防，寵物主人需要更多支持和資源來確保他們的寵物能夠過上幸福健康的生活。正是這樣的使命感，推動Eugene和Oscar開始了他們的創業之旅。
                            <br /><br />
                            Petalife，這個名字背後蘊含著對Oreo的愛和對每一隻寵物的熱情。Petalife 代表著對生命的珍惜，以及對寵物生活的關懷。這個名字代表著品牌的核心價值觀——提供關愛和教育，確保每一位寵物主人都能為他們的毛孩提供最好的照顧。
                            <br /><br />
                            Petalife的使命是為寵物主人提供高質量的產品和資源，以幫助他們更好地照顧他們的寵物。品牌不僅提供優質的寵物護理產品，還通過創新技術來改進寵物健康的監控。Petalife相信，通過提供健康護理、教育和支持，可以改變每一隻寵物和主人的生活。
                            <br /><br />
                            每一個產品，從護膚產品到保健零食，都經過精心研發和測試，確保它們的安全性和有效性。Petalife的愿景是將人類級別的護理標準引入寵物護理行業，確保每個毛小孩都能擁有健康、幸福的生活。
                            <br /><br />
                            Petalife的故事是一個充滿愛和熱情的旅程，始於對Oreo的記憶，漸進地變成對每一位寵物主人的承諾。這個承諾是對每一隻毛絨絨朋友的愛，是對每一位寵物主人的支持，是對寵物健康的關注，以及對創造更美好世界的渴望。Petalife將一直走在創新和堅守承諾的前沿，為每一位寵物主人和他們的寵物帶來更多的快樂和健康。
                        </div>
                    ) : (
                        <div>
                            <br />
                            Founders Eugene and Oscar are siblings and also pet enthusiasts. They spent many wonderful moments with a cute little dog named Oreo during their childhood. However, an unexpected health issue occurred with Oreo. Due to not receiving timely and proper medical treatment, Oreo suddenly passed away. This unfortunate event deeply touched the hearts of Eugene and Oscar, leaving them filled with guilt and regret.
                            <br /><br />
                            Eugene and Oscar deeply understood that they were not the only pet owners who had experienced such painful events. They realized that pet health issues require better monitoring and prevention, and pet owners need more support and resources to ensure their pets can lead happy, healthy lives. It was this sense of mission that propelled Eugene and Oscar to embark on their entrepreneurial journey.
                            <br /><br />
                            Petalife, a name imbued with love for Oreo and passion for every pet, represents a cherished life and care for the lives of pets. This name embodies the core values of the brand—providing care and education to ensure that every pet owner can give their furry friends the best possible care.
                            <br /><br />
                            Petalife's mission is to provide pet owners with high-quality products and resources to help them take better care of their pets. The brand offers premium pet care products and enhances pet health monitoring through innovative technology. Petalife believes that by providing healthcare, education, and support, it can transform the lives of every pet and their owner.
                            <br /><br />
                            Every product, from skincare items to health snacks, is meticulously developed and tested to ensure their safety and effectiveness. Petalife's vision is to bring human-level care standards to the pet care industry, ensuring that every furry friend can have a healthy and happy life.
                            <br /><br />
                            The story of Petalife is a journey filled with love and passion, beginning with the memory of Oreo and gradually evolving into a commitment to every pet owner. This commitment is a testament to the love for every furry friend, the support for every pet owner, the focus on pet health, and the desire to create a better world. Petalife will continue to lead in innovation and uphold its promises, bringing more happiness and health to every pet owner and their pets.
                        </div>
                    )}
                </Typography>
            </Box>
        </div>
    );
}

export default AboutUs;
